<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add New Set
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.name"
                :errors="error.errors.name"
                name="name"
                prop="name"
                label="Name"
                description="The name of the assembly."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.key"
                :errors="error.errors.key"
                name="key"
                prop="key"
                label="Key"
                description="The key that identifies this assembly."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.icon"
                :errors="error.errors.icon"
                name="icon"
                prop="icon"
                label="Icon"
                description="The Font Awesome icon name used for this assembly. Will show to the right if valid."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 space-y-1 px-2 mb-4">
              <p class="text-sm font-medium">Icon Preview:</p>
              <div class="flex items-center justify-center border border-shade w-16 h-16 rounded">
                <font-awesome-icon :icon="['fas', form.icon]" size="2x" fixed-width />
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.required"
                :errors="error.errors.required"
                name="required"
                prop="required"
                label="Required?"
                type="checkbox"
                description="Checked if this assembly is required have a product selected on Builder pages."
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fas', 'save']"
            type="submit"
            success
            @click="submitForm"
          >
            Add Assembly
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { cloneDeep } from 'lodash'
import HasSlug from '@/mixins/HasSlug'
import NewModelLayout from '@/components/layouts/NewModelLayout'

export default {
  name: 'AssembliesNew',

  components: {
    NewModelLayout
  },

  mixins: [
    HasSlug
  ],

  data () {
    return {
      loading: true,
      sets: [],
      form: {},
      defaults: {
        name: '',
        key: '',
        icon: '',
        required: false
      },
      error: {
        errors: {}
      }
    }
  },

  watch: {
    'form.name': function (val) {
      this.form.key = this.getSlug(val)
    }
  },

  mounted () {
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
    },

    resetForm () {
      this.initForm()
    },

    submitForm () {
      this.$refs.form.validate()
      this.$api.post('assemblies', this.form)
        .then((res) => {
          this.$router.push({
            name: 'assemblies.single',
            params: {
              model: res.data.id
            }
          })
        })
        .catch((error) => {
          this.error = error
        })
    }
  }
}
</script>
